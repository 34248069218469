import {BookOpen, DollarSign, Server, Package} from 'react-feather';
import {Link} from 'gatsby';
import React from 'react';
import './about-us.css';

import Layout from '../components/layout';
import SEO from '../components/seo';

const AboutUsPage = () => (
  <Layout overflow={false}>
    <SEO title="About Us - Fleet Serverless function fastest" />
    <section className="relative mt-24 px-6 lg:px-12">
      <div className="container flex flex-col lg:flex-row">
        <div className="flex flex-col w-full lg:w-3/6 lg:pr-12">
          <p className="text-gray mb-2">OUR MISSION</p>
          <h1 className="text-5xl leading-tight font-semibold">
            Deliver next generation serverless technologies
          </h1>
          <p className="text-gray mt-8 leading-relaxed">
            With Fleet's serverless technology that enables companies, startups
            and developers to create new experience-centric products and less
            server dependencies, we will be delivering new serverless-based
            products that make it easy for companies to focus on just their core
            product.
          </p>
        </div>
        <div className="flex w-full lg:w-3/6 relative lg:pl-12 mt-16 lg:mt-0">
          <div className="hidden grid-contact lg:block w-full h-full z-0 absolute">
            <div className="grid-bg" />
          </div>
          <div className="shadow-xl bg-white px-6 py-8 w-full z-1 relative">
            <div className="flex">
              <div className="w-2/6">
                <div className="flex flex-col items-center justify-center border-gray-400 border-dashed border-2 rounded-lg px-2 h-20 my-3 mx-3">
                  <div className="text-gray-light-100 triangle-up w-10 h-10" />
                </div>
              </div>
              <div className="w-2/6">
                <div className="flex flex-col items-center justify-center border-gray-400 border-dashed border-2 rounded-lg px-2 h-20 my-3 mx-3">
                  <div className="bg-gray-light w-10 h-10" />
                </div>
              </div>
              <div className="w-2/6">
                <div className="flex flex-col items-center justify-center border-gray-400 border-dashed border-2 rounded-lg px-2 h-20 my-3 mx-3">
                  <Link
                    to="/traffic-shifting"
                    className="hover:effect-scale transition-cb-1"
                  >
                    <img
                      className="select-none min-w-45"
                      src="/images/logo-fleet-ts.svg"
                      alt="Fleet Traffic Shifting"
                    />
                  </Link>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-center bg-primary rounded-lg px-2 py-3 my-3 mx-3">
              <Link
                to="/function"
                className="hover:effect-scale transition-cb-1 relative w-12 h-12 mb-3"
              >
                <img
                  className="select-none"
                  src="/images/logo-fleet-function-white.svg"
                  alt="Fleet Function"
                />
              </Link>
              <p className="text-white font-semibold">Fleet Function</p>
            </div>
            <div className="diagram-infra">
              <div className="flex">
                <div className="w-2/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
                <div className="w-3/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
                <div className="w-1/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
              </div>
              <div className="flex">
                <div className="w-1/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
                <div className="w-2/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
                <div className="w-3/6">
                  <div className="border-primary border-dashed border-2 rounded-lg px-2 py-5 my-3 mx-3" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="bg-gray-light mt-24 py-16 px-6">
      <div className="container">
        <h2 className="text-center text-4xl leading-tight font-normal my-4">
          Solutions rooted in code and design
        </h2>
        <p className="text-info-center text-center text-xl text-gray">
          Companies need to accelerate the process of developing their products,
          Fleet provides friendly code-based tools and technology.
        </p>
        <div className="flex flex-col sm:flex-row mt-12">
          <div className="w-full sm:w-3/6 sm:pr-6">
            <ul className="mt-8">
              <li className="flex flex-row py-6">
                <div className="flex-shrink-0">
                  <BookOpen color="#000" size={24} />
                </div>
                <div className="flex-1 ml-4">
                  <h3 className="text-lg font-semibold mb-2">Friendly docs</h3>
                  <p className="text-gray leading-relaxed">
                    Our documentation is extensive and explanatory to be
                    friendly to developers
                  </p>
                </div>
              </li>
              <li className="flex flex-row py-6">
                <div className="flex-shrink-0">
                  <DollarSign color="#000" size={24} />
                </div>
                <div className="flex-1 ml-4">
                  <h3 className="text-lg font-semibold mb-2">Pay per use</h3>
                  <p className="text-gray leading-relaxed">
                    We build serverless technologies so that companies pay only
                    for what is used
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="w-full sm:w-3/6 sm:pr-6">
            <ul className="mt-8">
              <li className="flex flex-row py-6">
                <div className="flex-shrink-0">
                  <Server color="#000" size={24} />
                </div>
                <div className="flex-1 ml-4">
                  <h3 className="text-lg font-semibold mb-2">
                    Removing server complexity
                  </h3>
                  <p className="text-gray leading-relaxed">
                    We are building the next generation of serverless technology
                    to help companies focus on their product
                  </p>
                </div>
              </li>
              <li className="flex flex-row py-6">
                <div className="flex-shrink-0">
                  <Package color="#000" size={24} />
                </div>
                <div className="flex-1 ml-4">
                  <h3 className="text-lg font-semibold mb-2">Easy</h3>
                  <p className="text-gray leading-relaxed">
                    Building and deploying should be easy and simple in the
                    development workflow
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-32 relative px-6 lg:px-0">
      <div className="container">
        <div className="hidden lg:block w-32 h-full z-0 absolute">
          <div className="grid-bg" />
        </div>
        <div className="pl-0 lg:pl-32">
          <h2 className="text-4xl leading-tight font-normal my-4">
            Contact Us
          </h2>
          <p className="text-xl text-gray">
            We'd love to hear from you. <br /> Please do not hesitate to contact
            us.
          </p>
          <p className="text-gray mt-12">sales@fleetfn.com</p>
          <p className="text-gray mt-2">hello@fleetfn.com</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default AboutUsPage;
